import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import {
  getChangerHabit,
  postHabitProgress,
  getChangerHabitProgress,
  updateChangerHabit,
  getFeedback,
  getUsersCoach,
} from '../api';
import SetupHabitModal from './Client/ClientHabit/SetupHabitModal';
import ActionInfo from './Client/ClientHabit/ActionInfo';
import ClientHabitPage from './Client/ClientHabit/ClientHabitPage';
import ClientStats from './Client/ClientHabit/ClientStats';

function ClientHome() {
  const [currentHabit, setCurrentHabit] = useState({});
  const [doneInfo, setDoneInfo] = useState('');
  const [didntDoInfo, setDidntDoInfo] = useState('');
  const [habitChecked, setHabitChecked] = useState(false);
  const [habitProgress, setHabitProgress] = useState({});
  const [hasHabit, setHasHabit] = useState(false);
  const [newHabitAlert, setNewHabitAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [when, setWhen] = useState('');
  const [where, setWhere] = useState('');
  const [how, setHow] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [coach, setCoach] = useState({});
  const [showHabitPage, setShowHabitPage] = useState(false);

  const closeModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    fetchChangerHabit();
    fetchCoach();
  }, []);

  const today = new Date().toString();
  const now = today.substring(0, 10);
  const nothingToDo = (habitChecked || !hasHabit) && !newHabitAlert;
  const workOnHabit = hasHabit && !newHabitAlert;
  const noHabitAssigned = !hasHabit && !newHabitAlert;

  const { dateStats, habitInfo } = habitProgress;

  function handleSubmitSetup() {
    const updateHabit = {
      when: when,
      where: where,
      how: how,
      id: currentHabit.id,
      formFilledIn: true,
    };
    updateChangerHabit(updateHabit).then((result) => {
      setNewHabitAlert(false);
      setHasHabit(true);
      setCurrentHabit(result);
      setShowModal(false);
    });
  }
  if (showHabitPage) {
    return (
      <ClientHabitPage
        setShowHabitPage={setShowHabitPage}
        currentHabit={currentHabit}
      />
    );
  }

  return (
    <PageWrap>
      <ActionInfo
        handleShowModal={handleShowModal}
        currentHabit={currentHabit}
        hasHabit={hasHabit}
        workOnHabit={workOnHabit}
        newHabitAlert={newHabitAlert}
        nothingToDo={nothingToDo}
        noHabitAssigned={noHabitAssigned}
        now={now}
        setDoneInfo={setDoneInfo}
        setDidntDoInfo={setDidntDoInfo}
        postHabit={postHabit}
        setShowHabitPage={setShowHabitPage}
      />
      <ClientStats
        hasHabit={hasHabit}
        dateStats={dateStats}
        habitInfo={habitInfo}
      />
      <CardWrap>
        {hasHabit && feedback.length > 0 && (
          <Card>
            {coach.name && <h3>{coach.name}'s feedback</h3>}
            {feedback.map((feedback) => (
              <ListGroup variant="flush" key={feedback.id}>
                <ListGroup.Item>{feedback.feedback}</ListGroup.Item>
              </ListGroup>
            ))}
          </Card>
        )}
        {hasHabit && habitProgress.habitProgress !== undefined && (
          <Card>
            <h3>Your personal wins</h3>
            {habitProgress.habitProgress.map((x) => (
              <ListGroup variant="flush" key={x.id}>
                <ListGroup.Item>{x.whats_working}</ListGroup.Item>
              </ListGroup>
            ))}
          </Card>
        )}
      </CardWrap>
      <SetupHabitModal
        submitSetup={handleSubmitSetup}
        closeModal={closeModal}
        showModal={showModal}
        setHow={setHow}
        setWhen={setWhen}
        setWhere={setWhere}
        currentHabit={currentHabit}
      />
    </PageWrap>
  );

  function postHabit(status) {
    const today = new Date();
    if (status === 'done') {
      const progress = {
        changer_habit_id: currentHabit.id,
        habit_done: true,
        whats_working: doneInfo,
        datetime: today,
      };
      postHabitProgress(progress).then((result) => {
        setHabitChecked(true);
      });
    } else if (status === 'nope') {
      // need a column not_working to add didntDoInfo
      const progress = {
        changer_habit_id: currentHabit.id,
        habit_done: false,
        datetime: today,
      };
      postHabitProgress(progress).then((result) => {
        // should really check if its a 200
        setHabitChecked(true);
      });
    }
  }

  function fetchCoach() {
    getUsersCoach().then((coach) => {
      setCoach(coach);
    });
  }

  function fetchChangerHabit() {
    getChangerHabit().then((habit) => {
      if (Object.keys(habit).length > 0) {
        if (habit.formFilledIn === false) {
          setNewHabitAlert(true);
          setCurrentHabit(habit);
        } else if (habit !== 'no habits') {
          setHasHabit(true);
          setCurrentHabit(habit);
          if (habit.id) {
            fetchHabitProgress(habit.id);
            fetchFeedback(habit.id);
          }
        }
      }
    });
  }

  function fetchFeedback(habitId) {
    getFeedback(habitId).then((feedback) => {
      setFeedback(feedback);
    });
  }

  function fetchHabitProgress(id) {
    getChangerHabitProgress(id).then((result) => {
      setHabitProgress(result);
      let todaysChecked = false;
      // check if todays has been done
      const today = new Date().toISOString().substring(0, 10);
      result.habitProgress.forEach((x) => {
        if (x.datetime?.substring(0, 10) === today && x.habit_done != null) {
          todaysChecked = true;
        }
      });
      setHabitChecked(todaysChecked);
    });
  }
}

export default ClientHome;

const PageWrap = styled.div`
  padding-top: 15px;
`;

const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 2em;
  margin: 2em;
  width: 500px;
`;
