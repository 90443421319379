import axios from 'axios';
const base = 'http://localhost:9080/api/';
axios.defaults.withCredentials = true;

export const getChangers = async () => {
  const { data } = await axios.get(`${base}users/allchangers`);
  return data;
};

export const getHabits = async () => {
  const { data } = await axios.get(`${base}habits`);
  return data;
};

export const postHabit = async (habit, description) => {
  const { data } = await axios.post(`${base}habits`, {
    habit_name: habit,
    description: description,
  });
  return data.habit;
};

export const deleteHabit = async (id) => {
  const { data } = await axios.delete(`${base}habits/${id}`);
  return data;
};

export const updateHabit = async (habit) => {
  const { data } = await axios.put(`${base}habits/${habit.id}`, habit);
  return data;
};

export const inviteChanger = async (changer) => {
  const { data } = await axios.post(`${base}users`, changer);
  return data.user;
};

export const getCoachStats = async (userId) => {
  const { data } = await axios.get(`${base}users/coachstats/${userId}`);
  return data;
};

// USERS

export const getUsersCoach = async () => {
  const { data } = await axios.get(`${base}users/getcoach`);
  return data;
};

export const addNewUser = async (user) => {
  const { data } = await axios.post(`${base}users/signup`, user);
  return data.user;
};

export const loginUser = async (user) => {
  const response = await axios.post(`${base}users/login`, user);
  return response;
};

export const getSignedInUser = async () => {
  const response = await axios.get(`${base}users`);
  return response;
};

export const signOutUser = async () => {
  const response = await axios.get(`${base}users/signup`);
  return response;
};

export const postHabitAssignment = async (newHabitAssign) => {
  const { data } = await axios.post(`${base}changershabits`, newHabitAssign);
  return data;
};

export const getChangerHabit = async () => {
  const { data } = await axios.get(`${base}changershabits`);
  return data;
};

export const getHabitById = async (id) => {
  const { data } = await axios.get(`${base}changershabits/${id}`);
  return data;
};

export const updateChangerHabit = async (habit) => {
  const { data } = await axios.put(`${base}changershabits/${habit.id}`, habit);
  return data;
};

// habit progress
export const postHabitProgress = async (progress) => {
  const { data } = await axios.post(`${base}habitprogress`, progress);
  return data;
};

export const getChangerHabitProgress = async (id) => {
  const { data } = await axios.get(`${base}habitprogress/${id}`);
  return data;
};

// feedback
export const postFeedback = async (feedback) => {
  const { data } = await axios.post(`${base}feedback`, feedback);
  return data;
};

export const getFeedback = async (id) => {
  const { data } = await axios.get(`${base}feedback/${id}`);
  return data;
};
