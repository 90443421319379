import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  InputGroup as BootstrapInputGroup,
  FormControl,
} from "react-bootstrap";
import { Button as StyledButton, OutlineButton } from "../../../styles/buttons";

function ActionInfo({
  handleShowModal,
  currentHabit,
  hasHabit,
  workOnHabit,
  newHabitAlert,
  nothingToDo,
  noHabitAssigned,
  setDoneInfo,
  setDidntDoInfo,
  postHabit,
  setShowHabitPage,
}) {
  const [didIt, setDidIt] = useState(false);
  const [didNot, setDidNot] = useState(false);

  function handleDidIt() {
    setDidIt(true);
    setDidNot(false);
  }

  function handleDidNot() {
    setDidNot(true);
    setDidIt(false);
  }

  return (
    <>
      {newHabitAlert && (
        <NewHabitAlert>
          <AlertText>Great news! Your coach has set you a new action</AlertText>
          <StyledButton onClick={handleShowModal}>
            Setup and start action
          </StyledButton>
        </NewHabitAlert>
      )}

      {/* show either of these */}
      {workOnHabit && (
        <HabitTitle onClick={() => setShowHabitPage(true)}>
          {currentHabit.habit_name}
        </HabitTitle>
      )}
      {!hasHabit && !newHabitAlert && (
        <h3>Your coach hasn't assigned you an action yet</h3>
      )}

      {/* show either of these */}
      {nothingToDo && !noHabitAssigned && (
        <AllDoneText>Great work, You're all done for today</AllDoneText>
      )}
      {workOnHabit && !nothingToDo && (
        <>
          <ButtonsWrap>
            <StyledOutlineButton onClick={() => handleDidIt()}>
              I did it
            </StyledOutlineButton>
            <StyledOutlineButton onClick={() => handleDidNot()}>
              I didn't do it
            </StyledOutlineButton>
          </ButtonsWrap>
          <InputWrap>
            {didIt && (
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="What helped you with this action today?"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={(event) => setDoneInfo(event.target.value)}
                />
                <InputGroup.Append>
                  <Button
                    onClick={() => postHabit("done")}
                    variant="outline-secondary"
                  >
                    Submit
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            )}
            {didNot && (
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="What got in your way today?"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={(event) => setDidntDoInfo(event.target.value)}
                />
                <InputGroup.Append>
                  <Button
                    onClick={() => postHabit("nope")}
                    variant="outline-secondary"
                  >
                    Submit
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            )}
          </InputWrap>
        </>
      )}
    </>
  );
}

export default ActionInfo;

const NewHabitAlert = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 2em 6em;
`;

const AlertText = styled.div`
  font-size: 1.275em;
  font-weight: 700;
  padding: 1.5em;
`;

const InputWrap = styled.div`
  margin: 3em 20em 3em 20em;
`;

const HabitTitle = styled.h1`
  color: #123c69;
  &:hover {
    color: grey;
    cursor: pointer;
  }
`;

const InputGroup = styled(BootstrapInputGroup)``;

const ButtonsWrap = styled.div`
  margin-top: 2em;
`;

const StyledOutlineButton = styled(OutlineButton)`
  color: black;
  margin-right: 10px;
  font-size: 1.5em;

  &:hover {
    background-color: #123c69 !important;
  }

  &:active {
    background-color: #123c69 !important;
  }
`;

const AllDoneText = styled.div`
  font-size: 2em;
  margin: 1.5em;
`;
