import React from "react";
import styled from "styled-components";
import { Modal as BootstrapModal, Form } from "react-bootstrap";
import { Button } from "../../../styles/buttons";

function SetupHabitModal({
  showModal,
  closeModal,
  submitSetup,
  setHow,
  setWhere,
  setWhen,
  currentHabit,
}) {
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Setup your Action: {currentHabit.habit_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>When will you do this in your day?</Form.Label>
            <Form.Control
              onChange={(event) => setWhen(event.target.value)}
              type="when"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Where will you do this?</Form.Label>
            <Form.Control
              onChange={(event) => setWhere(event.target.value)}
              type="where"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>
              How could you shape your environment to help you do this?
            </Form.Label>
            <Form.Control
              onChange={(event) => setHow(event.target.value)}
              type="how"
            />
          </Form.Group>
          <Button variant="primary" onClick={submitSetup}>
            Start this action
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SetupHabitModal;

const Modal = styled(BootstrapModal)`
  margin-top: 100px;
`;
