import React from "react";
import styled from "styled-components";

function Stat({ stats, text }) {
  return (
    <Wrap>
      <Data>{stats}</Data>
      <Text>{text}</Text>
    </Wrap>
  );
}
const Wrap = styled.div`
  padding: 1.5em;
`;

const Text = styled.span`
  font-size: 1.25em;
`;

const Data = styled.span`
  color: #123c69;
  font-weight: 800;
  font-size: 2.5em;
  margin-right: 8px;
`;

export default Stat;
