import React from "react";
import styled from "styled-components";

function ClientStats({ hasHabit, dateStats, habitInfo }) {
  return (
    <>
      {hasHabit && (
        <div>
          {dateStats !== undefined && (
            <Stat>
              <ProgressInfo>
                {dateStats.daysDone}/{dateStats.totalDays}
              </ProgressInfo>
              <ProgressText>days complete</ProgressText>
            </Stat>
          )}
          {habitInfo !== undefined && (
            <>
              <Stat>
                <ProgressInfo>{habitInfo.completionRate}%</ProgressInfo>
                <ProgressText>completion rate</ProgressText>
              </Stat>
              <Stat>
                <ProgressInfo>{habitInfo.successRate}%</ProgressInfo>
                <ProgressText>success rate</ProgressText>
              </Stat>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ClientStats;

const ProgressText = styled.span`
  font-size: 1.25em;
`;

const ProgressInfo = styled.span`
  color: #123c69;
  font-weight: 800;
  font-size: 2.5em;
  margin-right: 8px;
`;

const Stat = styled.div`
  padding: 0.5em;
`;
