import React from "react";
import styled from "styled-components";

function ClientHabitPage({ currentHabit, setShowHabitPage }) {
  return (
    <>
      <ActionTitle>Current Action: {currentHabit.habit_name}</ActionTitle>
      <Wrapper>
        <ActionCard>
          <h4>
            <ActionInfo>When:</ActionInfo> {currentHabit.when}
          </h4>
          <h4>
            <ActionInfo>Where:</ActionInfo> {currentHabit.where}
          </h4>
          <h4>
            <ActionInfo>Shape:</ActionInfo> {currentHabit.how}
          </h4>
        </ActionCard>
        <ActionCard>
          <h4>Coach tips for this habit</h4>
        </ActionCard>
        <ActionCard>
          <h4>Motivate</h4>
        </ActionCard>
        <ActionCard>
          <h4>Shape your environment</h4>
        </ActionCard>
      </Wrapper>
    </>
  );
}

export default ClientHabitPage;

const ActionTitle = styled.div`
  font-size: 2em;
  font-weight: 700;
  padding-top: 1em;
  padding-bottom: 1em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ActionCard = styled.div`
  background-color: white;
  text-align: left;
  margin: 1em;
  padding: 1.5em;
  width: 25%;
`;

const ActionInfo = styled.div`
  font-weight: 700;
  color: grey;
`;
