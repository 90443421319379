import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card as BootstrapCard, Container, ListGroup } from 'react-bootstrap';
import { getCoachStats } from '../api';

import Stat from './SmallComponents/Stat';

function CoachHome({ user }) {
  const [stats, setStats] = useState([]);
  const userId = user.sub;

  console.log('id', userId);

  useEffect(() => {
    fetchCoachStats();
  }, []);

  function renderFeedback(type) {
    return stats?.changers?.map((changer) => {
      // need to loop over each users feedback
      // show only todays feedback?
      if (changer?.userProgress) {
        const feedback = changer?.userProgress?.habitProgress[0];
        console.log('changer', feedback);
        // where datetime === today
        const today = new Date().toISOString().substring(0, 10);
        const isTodays = feedback.datetime?.substring(0, 10) === today;
        console.log('IS TODAY', isTodays);
        if (type === 'working' && feedback.whats_working !== null) {
          return (
            <ListGroup.Item>
              {changer.name}: {feedback.whats_working}
            </ListGroup.Item>
          );
        }
        // need a whats not working column
        // return (
        //   <ListGroup.Item>
        //     {changer.name}: {feedback.whats_working}
        //   </ListGroup.Item>
        // );
      }
    });
  }
  // TODO - empty state of stats

  return (
    <Wrap>
      <BigCard>
        <Card.Body>
          <CardTitle>Changer stats</CardTitle>
          <BigCardInner>
            <Stat stats={stats?.changers?.length} text="changers" />
            <Stat
              stats={stats?.totalNoActions}
              text={`changer${
                stats?.totalNoActions !== 1 ? 's' : ''
              } with no actions`}
            />
            <Stat
              stats={`${stats?.averageSuccess}%`}
              text="average success rate"
            />
          </BigCardInner>
        </Card.Body>
      </BigCard>
      <CardWrap>
        <Card>
          <Card.Body>
            <CardTitle>What's working</CardTitle>
            <CardInner>
              <ListGroup variant="flush">{renderFeedback('working')}</ListGroup>
            </CardInner>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <CardTitle>Todays obstacles</CardTitle>
            <CardInner>
              <div>no obstacles, awesome work</div>
              {/* <ListGroup variant="flush">
                {renderFeedback("obstacles")}
              </ListGroup> */}
            </CardInner>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <CardTitle>Coach Tip</CardTitle>
            <CardInner>
              <TipHead>Listening</TipHead>
              <TipText>
                Stay present, focus on listening to each word. Make sure you
                fully understand what your client is saying. Don't be eager to
                get your say in.
              </TipText>
            </CardInner>
          </Card.Body>
        </Card>
      </CardWrap>
    </Wrap>
  );

  function fetchCoachStats() {
    getCoachStats(userId)
      .then((stats) => {
        setStats(stats);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

const Wrap = styled(Container)`
  padding-top: 2em;
`;

const CardWrap = styled.div`
  @media (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const BigCard = styled(BootstrapCard)``;

const Card = styled(BootstrapCard)`
  margin: 1em;
  @media (max-width: 575px) {
    margin: 1em 0 0 0;
  }
`;

const CardTitle = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  color: #123c69;
`;

const CardInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  max-width: 300px;
  text-align: left;
`;

const BigCardInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  justify-content: center;
  @media (max-width: 575px) {
    text-align: left;
    justify-content: unset;
  }
`;

const TipHead = styled.div`
  font-size: 1.125em;
  font-weight: 700;
`;

const TipText = styled.div``;

export default CoachHome;
