import React from 'react';
import styled from 'styled-components';
import { Router } from '@reach/router';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './components/homePage';

import LandingPage from './components/LandingPage';
import Nav from './components/Nav';
import './App.css';

function App() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  // redirect to landing page if not signed in
  // {
  //   !isAuthenticated && <Route path="/" exact component={Home} />;
  // }
  //

  return (
    <div>
      {isAuthenticated && <Nav />}
      <WholePage>
        {/* <Router className="App"> */}
        {!isAuthenticated && <LandingPage path="/" user={user} />}
        {isAuthenticated && <HomePage path="/dashboard" user={user} />}
        {/* <Changers path="/changers" user={user} />
        <HabitSetup path="/habitsetup" user={user} />
        <TheChanger path="/thechanger/:id" user={user} /> */}
        {/* </Router> */}
      </WholePage>
    </div>
  );
}

export default App;

const WholePage = styled.div`
  padding-top: 50px;
`;
