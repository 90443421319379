import React from 'react';
import CoachHome from './CoachHome';
import ClientHome from './ClientHome';

function homePage({ user }) {
  if (!user) {
    return null;
  }

  // sort out the roles here coach vs client

  if (user) {
    return <CoachHome user={user} />;
  } else if (user.coach !== true) {
    return <ClientHome user={user} />;
  }
}

export default homePage;
