import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

function Nav() {
  const { logout } = useAuth0();

  function handleSignOut() {
    logout({ returnTo: window.location.origin });
  }

  return (
    <Wrap>
      <NavItem>Dashboard</NavItem>
      <NavItem>Changers</NavItem>
      <NavItem>Habits</NavItem>
      <NavItem onClick={() => handleSignOut()}>Logout</NavItem>
    </Wrap>
  );
}
export default Nav;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 36px;
  line-height: 36px;
  background-color: #fbf7f0;
  z-index: 1001;
  border-bottom: 1px solid lightgrey;
`;

const NavItem = styled.div`
  padding: 0 10px;
`;
