import styled from "styled-components";
import { Button as bootstrapButton } from "react-bootstrap";

export const Button = styled(bootstrapButton)`
  background-color: #123c69 !important;
  border-color: #123c69 !important;
`;

export const OutlineButton = styled(bootstrapButton)`
  background-color: transparent !important;
  border-color: #123c69 !important;
`;
