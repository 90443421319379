import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

function LandingPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Wrap>
      <Header>Become a super coach</Header>
      {/* <SignInButton type="button" onClick={() => loginWithRedirect()}>
        Sign in
      </SignInButton> */}
    </Wrap>
  );
}

export default LandingPage;

const Wrap = styled.div`
  padding-top: 50px;
`;

const Header = styled.div``;

const SignInButton = styled.button`
  font-weight: 700;
`;
